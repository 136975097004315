import React from 'react';
import { Link } from 'react-router-dom';
import Seo from "../components/Seo";
import ContactForm from '../components/ContactForm';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const Contact = () => {
    
    return (
        <>
        <Seo 
            title="Contact us " 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
        />
        <ScrollToTopOnMount />
            <div className="page-title dark-background" style={{ backgroundImage: `url(/images/categories/banner/categories.avif)` }}>
                <div className="container position-relative">
                    <h1>Contact us</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li className="current">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <ContactForm />
        </>
    );
};

export default Contact;