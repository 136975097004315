import React, { useState } from 'react';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        company: '',
        subject: '',
        message: ''
      });
      const [response, setResponse] = useState(null);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setResponse(null);
        try {
          const res = await fetch('/api/contact.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
          const result = await res.json();
          setResponse(result);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
    return (
        <>
            <section id="contact" className="contact section">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <form method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="400" onSubmit={handleSubmit}>
                        <div className="row gy-4">
                            <div className="col-md-6">
                                <input type="text" className="form-control" name="name" placeholder="Your Name" onChange={handleChange} required />
                            </div>
                            <div className="col-md-6 ">
                                <input type="email" className="form-control" name="email" placeholder="Your Email" onChange={handleChange} required />
                            </div>
                            <div className="col-md-6">
                                <input type="text" className="form-control" name="mobile" placeholder="Your Mobile" onChange={handleChange} required />
                            </div>
                            <div className="col-md-6 ">
                                <input type="text" className="form-control" name="company" placeholder="Your Company" onChange={handleChange} required />
                            </div>
                            <div className="col-md-12">
                                <input type="text" className="form-control" name="subject" placeholder="Subject" onChange={handleChange} required />
                            </div>
                            <div className="col-md-12">
                                <textarea className="form-control" name="message" rows="6" placeholder="Message" onChange={handleChange} required></textarea>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                                <button type="submit">Send Message</button>
                            </div>

                        </div>
                    </form>
                    {response && <div className='alert-info'>{response.message}</div>}
                </div>
            </section>
        </>
    );
};

export default ContactForm;