import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ScrollToTop from './ScrollToTop';

const Footer = () => {
    const [data, setData] = useState([]);
  
    useEffect(() => {
      fetch('/json/categories.json')
        .then((response) => response.json())
        .then((data) => setData(data));
    }, []);
    
    return (
        <>
            <ScrollToTop />
            <div className="footer-curve"></div>
            <footer id="footer" className="footer dark-background">
                <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 footer-about">
                        <a href="index.html" className="logo d-flex align-items-center">
                            <span className="sitename">BlueBaker</span>
                        </a>
                        <div className="footer-contact pt-3">
                            <p>Sharjah</p>
                            <p>United Arab Emirates</p>
                            <p className="mt-3"><strong>Phone:</strong> <span></span></p>
                            <p><strong>Email:</strong> <span></span></p>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 footer-links">
                        <h4>Links</h4>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About us</Link></li>
                            <li><Link to="/categories">Products</Link></li>
                            <li><Link to="/contact">Contact us</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-4 footer-links">
                        <h4>Our Products</h4>
                        <ul>
                            {data.slice(0,5).map((item) => (
                                <li key={item.Code}><Link to={`/category/${item.Code}`}>{item.Title}</Link></li>
                            ))}
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-4 footer-links">
                        <ul>
                            {data.slice(5,10).map((item) => (
                                <li key={item.Code}><Link to={`/category/${item.Code}`}>{item.Title}</Link></li>
                            ))}
                        </ul>
                    </div>


                </div>
                </div>

                <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> <strong className="px-1 sitename">BlueBaker</strong> <span>All Rights Reserved</span></p>
                </div>

                </footer>
        </>
        
    );
  }
  
  export default Footer;