import { useState, useEffect } from 'react';
import Footer from './Footer';
import MyIcon from '../assets/images/bluebaker-logo.svg';
import { Link, Outlet } from 'react-router-dom';

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [navbarColor, setNavbarColor] = useState('transparent');

    const className = showMenu ? 'mobile-nav-toggle d-xl-none bi bi-x' : 'mobile-nav-toggle d-xl-none bi bi-list';
    const mobileNavToogle = () => {
        document.querySelector('body').classList.toggle('mobile-nav-active');
        return setShowMenu(!showMenu);
    }

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition > 200) {
          setNavbarColor('rgba(0,0,0,0.9)'); // Change to desired color after scrolling
        } else {
          setNavbarColor('transparent'); // Default color
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

      
    return (
        <>
            <header id="header" className="header d-flex align-items-center fixed-top p-1" style={{ backgroundColor: navbarColor }} >
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between"> 
                    <Link to="/" className="logo d-flex align-items-center pt-1">
                        <img src={MyIcon} title='BlueBaker' alt='BlueBaker' width={180} />
                    </Link>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><Link onClick={mobileNavToogle} className="px-lg-4 nav-link text-uppercase" to='/'> Home </Link></li>
                            <li><Link onClick={mobileNavToogle} className="px-lg-4 nav-link text-uppercase" to='/about'> About </Link></li>
                            <li><Link onClick={mobileNavToogle} className="px-lg-4 nav-link text-uppercase" to='/categories'> Products </Link></li>
                            <li><Link onClick={mobileNavToogle} className="px-lg-4 nav-link text-uppercase" to='/contact'> Contact </Link></li>
                        </ul>
                        <i className={className} onClick={mobileNavToogle} />
                    </nav>
                </div>
            </header>
            <div className='container-fuid pb-5' style={{minHeight: '900px'}}>
                <Outlet />
            </div>
            <Footer /> 
                 
        </>


    )
};
export default Header;