import React from 'react';
import { Carousel } from 'react-bootstrap';
import IntroImage from '../assets/images/slides/avif/intro.avif';
import IntroImage1 from '../assets/images/slides/avif/intro-01.avif';
import IntroImage2 from '../assets/images/slides/avif/intro-02.avif';
import IntroImage3 from '../assets/images/slides/avif/intro-03.avif';
import IntroImage4 from '../assets/images/slides/avif/intro-04.avif';

const CarouselComponent = () => {
  return (
    <Carousel autoPlay={true} interval={2000} controls={true} indicators={false}>
      <Carousel.Item>
        <img
          className="d-block"
          src={IntroImage2}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src={IntroImage1}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src={IntroImage}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src={IntroImage3}
          alt=""
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          src={IntroImage4}
          alt=""
        />
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselComponent;
