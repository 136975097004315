import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages';
import Categories from './pages/Categories';
import Contact from './pages/contact'
import NotFound from './pages/NotFound';
import Category from './pages/Category';
import Product from './pages/Product';
import About from './pages/About';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Header />}>
                <Route path='*' element={<NotFound />} />
                <Route index element={<Home />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/category/:id" element={<Category />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/about" element={<About />} />
            </Route>     
        </>
    )
)


const App = () => {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}

export default App;