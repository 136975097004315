import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Row } from 'react-bootstrap';
import Seo from '../components/Seo';
import SideMenu from '../components/SideMenu';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Loader from '../components/Loader';

const Category = () => {
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        fetch('/json/products.json')
            .then((response) => response.json())
            .then((data) => {
                const filteredData = data.filter(item => item.CategoryCode === id);
                setData(filteredData);
            });
        fetch('/json/categories.json')
            .then(response => response.json())
            .then(cat => {
                const selectedCat = cat.find(i => i.Code === id);
                setCat(selectedCat);
            });
      }, [id]);

      if (!cat) return <Loader />;
      
    return (
        <>
            {cat.Title && 
                <Seo 
                    title={cat.Title} 
                    description={cat.Description} 
                    keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
                />
            }
            <ScrollToTopOnMount />
            <div className="page-title dark-background" style={{ backgroundImage: `url(/images/categories/banner/${cat.Code}.avif)` }}>
                <div className="container position-relative">
                    <h1>{cat.Title}</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/categories">Products</Link></li>
                            <li className="current">{cat.Title}</li>
                        </ol>
                    </nav>
                    <SideMenu />
                </div>
            </div>
            <div className='container text-center clearfix'>
                <div className="container bg-faded rouded-bottom pt-5 clearfix">
                    <p>{cat.Description}</p>
                </div>
                <section id="projects" className="projects section">
                    <div className="container">
                        <Row xs={2} sm={2} md={4} className="g-4 justify-content-center">
                            {data.map(item => (
                                <div key={item.SKU} className='category' >
                                    <div className='category-image border'>
                                        <Link to={`/product/${item.Slug}`} title={item.Name}>
                                            <img className="category-img mx-auto d-flex rounded-top img-fluid" src={`/images/products/thumbs/avif/${item.SKU}.avif`} onError={(e) => {e.target.src = `/images/products/thumbs/webp/${item.SKU}.webp`}} title={item.Name} alt="..." />
                                        </Link>
                                    </div>
                                    <div className='category-title bg-dark text-white text-center rounded-bottom p-3'>{item.SKU}<br/><Link to={`/product/${item.Slug}`} title={item.Name}>{item.Name}</Link></div>
                                </div>
                            ))}
                        </Row>
                    </div>
                </section>
                <Link className="btn btn-primary mt-3 mb-5" to={`/categories`} title='Categories'> Our Products </Link>
            </div>
        </>
    )
}
export default Category;