import React, { useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import Seo from '../components/Seo';
import Loader from '../components/Loader';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';

const Product = () => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    
    useEffect(() => {
        fetch('/json/products-details.json')
        .then(response => response.json())
        .then(data => {
            const selectedItem = data.find(i => i.Slug === id);
            setItem(selectedItem);
        });
    }, [id]);
    
    if (!item) return <Loader />;

    return (
        <>
            {item.Name && 
                <Seo 
                    title={item.Name} 
                    description={item.Seo} 
                    keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
                />
            }
            <ScrollToTopOnMount />
            <div className="page-title dark-background" style={{ backgroundImage: `url(/images/categories/banner/${item.CategoryCode}.avif)` }}>
                <div className="container position-relative">
                    <h1>{item.Name}</h1>
                    <nav className="breadcrumbs">
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/categories">Products</Link></li>
                            <li><Link to={`/category/${item.CategoryCode}`}>{item.Categories}</Link></li>
                            <li className="current">{item.Name}</li>
                        </ol>
                    </nav>
                </div>
            </div>        
            <div className='container rounded pt-5'>
                <div className='col bg-white text-center'>
                    <img className="category-img mx-auto d-flex rounded img-fluid mb-3 mb-lg-0" src={`/images/products/avif/${item.SKU}.avif`} title={item.Name} alt="..." />
                </div>
                <div className='col bg-white p-2 p-md-5'>
                    <div dangerouslySetInnerHTML={{__html: item.Seo }} />
                </div>
                <div className='col bg-white text-center'>
                    <Link className="btn btn-primary mt-3 mb-5 m-5" to={`/category/${item.CategoryCode}`} title={item.Categories}> Back to {item.Categories}</Link>
                </div>
            </div>
        </>
    )
}
export default Product;