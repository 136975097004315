import Spinner from 'react-bootstrap/Spinner';        

function Loader() {
  return (
    <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
        Loading...
        <div className="spinner-border" role="status">
          <Spinner className='mx-auto p-5 m-5' animation="grow" variant="primary" />;
        </div>
    </div>
  )
}

export default Loader;