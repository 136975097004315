import React from 'react';
//import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Seo = ({ title, description, image, keywords }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>BueBaker | {title}</title>
        <meta name="description" content={description} />
        {keywords && <meta name="keywords" content={keywords} />}
        {image && <meta property="og:image" content={image} />}
      </Helmet>      
    </HelmetProvider>

  );
}

export default Seo;
