import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Seo from '../components/Seo';
import { Row } from 'react-bootstrap';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Loader from '../components/Loader';

const Categories = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/json/categories.json')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
    });
  }, []);

    const getFirstWords = (str) => { return str.split(' ').slice(0, 18).join(' '); }
    if (!data) return <Loader />;

  return (
    <>
        <Seo 
            title="Products range " 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
        />
        <ScrollToTopOnMount />
        <div className="page-title dark-background" style={{ backgroundImage: `url(/images/categories/banner/categories.avif)` }}>
            <div className="container position-relative">
                <h1>Our Products</h1>
                <nav className="breadcrumbs">
                    <ol>
                        <li><Link to="/">Home</Link></li>
                        <li className="current">Products</li>
                    </ol>
                </nav>
            </div>
        </div>
        <section id="projects" className="projects section">
            <div className="container">
                <Row xs={1} md={2} className="g-4 justify-content-center pt-5">
                    {data.map(x => (
                        <div key={x.Code} className='category' >
                            <div className='category-image'>
                                <Link to={`/category/${x.Code}`} title={x.Title}><img className="category-img mx-auto d-flex rounded-top img-fluid mb-md-3 mb-lg-0" src={`/images/categories/avif/${x.Code}.avif`} onError={(e) => {e.target.src = `/images/categories/webp/${x.Code}.webp`}} title={x.Title} alt="..." /></Link>
                            </div>
                            <div className='category-title bg-dark text-white text-center p-3'>{x.Title}</div>
                            <div className='category-description bg-light text-dark text-left rounded-bottom border border-info p-3 d-none d-sm-block'>{getFirstWords(x.Description)} 
                                ...
                            </div>
                        </div>
                    ))}
                </Row>
            </div>
        </section>

    </>
  );
};

export default Categories;
