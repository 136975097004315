import React from 'react';

const AboutUs = () => {
    return (
        <>
            <section id="contact" className="contact section">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                        <div className="col-md-12 text-left">
                            <div>
                                <p>At <b>BlueBaker</b>, we are dedicated to providing the highest quality bakery ingredients to our customers. Our promise is built on the following principles:</p>
                                <p><b>Quality Ingredients:</b> We source only the finest raw materials, ensuring that every ingredient meets our rigorous quality standards. Our ingredients are natural, wholesome, and free from artificial additives.</p>
                                <p><b>Innovation:</b> We continuously invest in research and development to create innovative products that meet the evolving needs of bakers and consumers. Our team of certified flavourists, bakery specialists, and food scientists work together to develop unique and delicious bakery mixes and bases. Customer Focus: We are fanatically bakery-focused and customer-obsessed. Our goal is to provide customized solutions that meet the specific needs of our customers, whether it's a unique flavour or a specific product format.</p>
                                <p><b>Sustainability:</b> We are committed to sustainability in all aspects of our business. From sourcing eco-friendly ingredients to implementing environmentally responsible manufacturing processes, we strive to make a positive impact on the planet.</p>
                                <p><b>Consistency:</b> We ensure that our products are consistently high in quality, meeting the expectations of our customers every time. Our state-of-the-art facilities and stringent quality control measures guarantee that every batch of our ingredients is top-notch.</p>
                                <p><b>Clean Label:</b> We believe in transparency and simplicity. Our products are made from ingredients that customers can pronounce, and we avoid modified ingredients to provide clean-label products that consumers can trust.</p>
                                <p><b>Unlimited Possibilities:</b> No matter what you are looking for, we offer a variety of formats and concentrates to meet your product and process requirements. If we don't have it, we will make it for you!</p>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;