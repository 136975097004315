import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Seo from '../components/Seo';
import AboutUs from '../components/AboutUs';
import ContactForm from '../components/ContactForm';
import { Button } from 'react-bootstrap';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Loader from '../components/Loader';
import CarouselComponent from '../components/CarouselComponent';

import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const [data, setData] = useState([]);
  
  useEffect(() => {
    fetch('/json/categories.json')
      .then((response) => response.json())
      .then((data) => {
        const slicedItems = data.slice(0, 9)
        setData(slicedItems)}
      );
  }, []);

  if (!data) return <Loader />;

    return (
        <>
          <Seo 
            title="Bakery Ingredients | All your baking needs" 
            description="Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence."
            keywords="baking ingredients, cake mixes, fondants, fillings & toppings, cold glazes" 
          />
          <ScrollToTopOnMount />
          <section id="hero" className="hero section dark-background">
            <div className="info d-flex align-items-center">
              <div className="container">
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
                  <div className="col-lg-6 text-center">
                    <h4>BlueBaker Bakery Ingredients</h4>
                    <h2>All your baking needs</h2>
                    <p>Discover top-quality bakery ingredients at unbeatable prices with BlueBaker. We supply premium flour, sugar pastes, yeast, and everything else a baker needs to create delicious masterpieces. Whether you're a home baker or a professional pastry chef, our extensive range of high-grade ingredients ensures your creations are always a hit. Shop now and experience the difference with BlueBaker, your trusted partner in baking excellence.</p>
                    <Button onClick={() => {
                        const element = document.getElementById('services');
                        element?.scrollIntoView({
                          behavior: 'smooth'
                        }); 
                      }} className="btn-get-started">Our Catalog</Button>
                  </div>
                </div>
              </div>
            </div>
            <CarouselComponent />
          </section>
          <section id="services" className="services section light-background mt-5">
            <div className="container section-title" data-aos="fade-up">
              <h2>BlueBaker Product Range</h2>
              <p>All your baking needs</p>
            </div>
            <div className="container">

              <div className="row gy-4">
              {data.map(item => (
                <div key={item.Code} className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                  <div className="service-item  position-relative">
                    <div className="icon">
                      <img className="category-img mx-auto d-flex img-fluid mb-3 mb-lg-0" src={`/images/categories/icon/avif/${item.Code}.avif`} title={item.Title} alt="..." />
                    </div>
                    <h3>{item.Title}</h3>
                    <p>{item.Description}</p>
                    <Link to={`/category/${item.Code}`} title={item.Title} className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link>
                  </div>
                </div>
              ))}
              </div>
            </div>
          </section>   
          <section id="get-started" className="get-started section">

            <div className="container">

              <div className="row justify-content-between gy-4">

                <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                  <div className="content">
                    <h3>About us</h3>
                    <AboutUs />
                  </div>
                </div>

                <div className="col-lg-5" data-aos="zoom-out" data-aos-delay="200">
                  <div className="content">
                    <h3>Contact us</h3>
                    <ContactForm />
                  </div>
                </div>

              </div>

            </div>
            </section>  
        </>
    )
}

export default Home