import React from 'react';
import ErrorImage from '../assets/images/404.jpg'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
      <div className='container-fluid'>
        <div className='container-fluid'>
            <section className="page-section about-heading">
                <div className="container">
                    <img className="img-fluid rounded about-heading-img mb-3 mb-lg-0" src={ErrorImage} alt="..." />
                    <div className="about-heading-content">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 mx-auto text-center">
                                <div className="bg-faded rounded p-5">
                                    <h2 className="section-heading mb-4">
                                        <span className="section-heading-upper">404</span>
                                        <span className="section-heading-lower">Page Not Found</span>
                                    </h2>
                                </div>
                                <div className="intro-button mx-auto"><Link className="btn btn-primary btn-xl" to='/'> Back to Home </Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
    )
}

export default NotFound;